export const environment = {
  production: false,
  apiUrl: 'https://dev-api.gridz.io/api',
  monitoringAuthUrl: 'https://dev.gridz.io:8443/grafana/login',
  userRoles: {
    OrganisationAdmin: 1,
    EnvironmentAdmin: 2,
    Finance: 3,
    Consulting: 4,
    Build: 5,
    NoAccess: 6
  },
  salesforce: {
    authorizeUri: 'https://login.salesforce.com/services/oauth2',
    clientId: '3MVG9xB_D1giir9rubgU6J7K5FeqODTpiEkMD2rDnS40NrYKrpD1JW3rCx5EJT8EGd749IwC1JojahobdhRYY',
    redirectUri: 'https://dev.gridz.io/sfauth'
  },

  contractStatuses: {
    DELETED:  0,
    DEPLOYED: 1, // Signed off
    BRAND_NEW_AND_UNCONFIRMED: 2, // BrandNewAndUnconfirmed
    PENDING_FIRST_APPROVAL: 3, // PendingFirstApproval
    PENDING_FOLLOW_UP_APPROVAL: 4, // PendingFollowUpApproval
    APPROVED: 5,
    JOB_DISPATCHED_AND_BUILDING_IN_VCD: 6, // JobDispatchedAndBuildingInVcd
    JOB_COMPLETED_AND_AWAITING_ADMINS_SIGN_OFF: 7, // JobCompletedAndAwaitingAdminSignOff
    JOB_FAILED_AND_NEEDS_ADMIN_ATTENTION: 8, // JobFailedAndNeedsAdminAttention
    DECLINED: 9,
  },

  environmentStatuses: {
    PendingApproval: 3
  }
};
