<app-main-nav></app-main-nav>
<div class="container">

  <h1>How to connect to your VMs</h1>
  <p class="guide-summary bot-mar-lg">
    A short guide on how to connect to your Gridz Virtual Machines using SSH and RDP<br>
  </p>

  <section>
    <h2>Connect to Linux server via SSH</h2>
    <p>
      Secure Shell (SSH) is a command line utility used to remotely access Linux and Unix systems.<br>
      This allows you to securely create / modify / transfer files and execute software on a remote server.<br>
      <br>
      When you create a Linux server on Gridz, the credentials to connect to it via SSH sent to you via email as soon as it's deployed.<br>
      Please bear in mind that we only do this if you select the option to allow remote access to the VM as you're creating it.<br>
      You will also be asked to change your device's password after initially logging in.<br>
      <br>
      Please note that Ubuntu doesn't allow SSH access as <code>root</code> by default, and you'll be provided with a template account name instead.<br>
      Commands can be executed as root by typing <code>sudo su</code> and entering the template account's password.
    </p>

    <!-- <div class="img-container">
      <img>
    </div> -->

    <p>    
      If you missed this step, you can manually create a firewall rule to allow the VM internet access and an inbound NAT rule to the VM for SSH access to a port of your choosing, as seen below:<br>
    </p>

    <div class="img-container">
      <a href="/assets/images/instructions/gridz/firewall/1.png" target=_BLANK>
        <img src="/assets/images/instructions/gridz/firewall/1.png">
      </a>
    </div>

    <p>
      For added security, we advise limiting SSH access to specific IP addresses, to ensure only the correct person or team of people have access.
    </p>

    <!-- <div class="img-container">
      <img>
    </div> -->

    <h3>Getting started</h3>
    <p>
      Linux, Unix and MacOS have a native implementation of SSH.<br>
      If you want to connect via SSH on Windows, you'll need to download a third party client like PuTTy or Cmder.
    </p>

    <h3>Windows instructions</h3>
    <p>
      Cmder is an open source, aftermarket console emulator for Windows, aimed at improving the stock CMD and Powershell experience.<br>
      It emulates a few Linux commands for Windows, meaning you can get a feel for navigating the Linux CLI locally, if you really wanted to.<br>
      There's a full and mini version available. The full version will be required for SSH connectivity and other Unix commands on Windows.<br>      
      <br>
      The Cmder package is portable. Download and extract the files to a location of your choosing, and run cmder.exe<br>
    </p>
    <div class="img-container">
      <a href="/assets/images/instructions/windows/ssh/1.png" target=_BLANK>
        <img src="/assets/images/instructions/windows/ssh/1.png">
      </a>
    </div>

    <h3>MacOS instructions</h3>
    <p>
      Open the Launchpad and look for the Terminal application.<br>
      Alternatively, you can hit <code>cmd</code> + <code>spacebar</code> to search through installed applications and type "terminal" and press <code>enter</code>.<br>
    </p>

    <div class="img-container">
      <a href="/assets/images/instructions/mac/ssh/1.png" target=_BLANK>
        <img src="/assets/images/instructions/mac/ssh/1.png">
      </a>
    </div>

    <p>
      You can save a connection from the default terminal application like so:<br>
    </p>

    <div class="img-container">
      <a href="/assets/images/instructions/mac/ssh/2.png" target=_BLANK>
        <img src="/assets/images/instructions/mac/ssh/2.png">
      </a>
    </div>

    <div class="img-container">
      <a href="/assets/images/instructions/mac/ssh/3.png" target=_BLANK>
        <img src="/assets/images/instructions/mac/ssh/3.png">
      </a>
    </div>

    <div class="img-container">
      <a href="/assets/images/instructions/mac/ssh/4.png" target=_BLANK>
        <img src="/assets/images/instructions/mac/ssh/4.png">
      </a>
    </div>

    <h3>Linux (desktop environment) instructions</h3>
    <p>
      On many Linux desktops (such as GNOME, KDE, MATE, Cinnamon), you should be able to press <code>super</code> to search through your installed applications and local files.<br>
      Type in "terminal", and hit <code>enter</code>.

      You might also want to try the shortcut key combination: <code>super</code> + <code>alt</code> + <code>T</code>
    </p>

    <h3>Using SSH</h3>
    <p>
      With your SSH client ready, you can connect to a remote computer via SSH like so:<br>
      <br>
      <code>ssh [user]@[host]</code> - this specifies a user on the remote computer, and a host, which is the computer you want to connect to.<br>
      <br>
      <code>[user]</code> denotes a user on the computer. We'll provide you with the credentials for the root user, permitting total control of the operating system. You should always be extremely careful in this scenario, as root users can potentially execute commands and shell scripts that can <a href="https://www.independent.co.uk/life-style/gadgets-and-tech/news/man-accidentally-deletes-his-entire-company-with-one-line-of-bad-code-a6984256.html" target="blank">delete important data</a>, or render the operating system unusable.<br>
      <br>
      <code>[host]</code> can be an IP address or a domain name. For example, you can ssh to a computer on your home network by specifying its local IP (e.g. 192.168.0.45), or a remote computer via its public IP or URL.<br>
      <br>
      To SSH to a Linux VM in Gridz, you'll need to log in as root to the VM's public IP and port:<br>
      <code>ssh root@77.0.0.1 -p 2120</code><br>
      <br>
      If your host is successfully identified, you'll be prompted for a password.<br>
      <br>
      If you'd like to brush up on basic commands to help you navigate the Linux CLI, we recommend this guide over at <a href="https://www.codecademy.com/articles/command-line-commands" target="_BLANK">CodeCademy</a>.
    </p>
  </section>

  <section>
    <h2>Connect to Windows server via RDP</h2>
    <p>
      Remote Desktop Protocol (RDP) provides the graphical Windows user interface into a target Windows Server VM over the internet.<br>
      <br>
      When you create a Windows server on Gridz, we email you the admin credentials along with an RDP configuration file as soon as it's deployed.<br>
      Be sure to check the option to allow remote access to the VM as you're creating it.<br>
    </p>
    <p>
      If you missed this step, you can create a firewall rule to allow the VM internet access and an inbound NAT rule to the VM for RDP access to a port of your choosing, as seen below:
    </p>

    <!-- <div class="img-container">
      <img>
    </div> -->

    <p>  
      For added security, we advise limiting RDP access to specific IP addresses.
    </p>

    <!-- <div class="img-container">
      <img>
    </div> -->

    <h3>Getting started</h3>
    <p>
      Windows Pro and Enterprise versions have a native RDP application preinstalled.<br>
      MacOS users can find 'Microsoft Remote Desktop' version 8 (recommended) and version 10 from within the App Store.<br>
      If you're using a Linux desktop environment, we recommend using Remmina.
    </p>

    <h3>Windows instructions</h3>
    <p>
      Download and double-click on the provided .rdp config file.<br>
      You'll be prompted for an account name and password, Which will have been provided in the email following the VMs deployment.
    </p>

    <div class="img-container">
      <a href="/assets/images/instructions/windows/rdp/1.png" target=_BLANK>
        <img src="/assets/images/instructions/windows/rdp/1.png">
      </a>
    </div>

    <div class="img-container">
      <a href="/assets/images/instructions/windows/rdp/2.png" target=_BLANK>
        <img src="/assets/images/instructions/windows/rdp/2.png">
      </a>
    </div>

    <h3>MacOS instructions</h3>
    <p>
      Download and install the Microsoft Remote Desktop client <a target="_BLANK" href="https://itunes.apple.com/gb/app/microsoft-remote-desktop-8/id715768417">Version 8</a> or <a target="_BLANK" href="https://itunes.apple.com/gb/app/microsoft-remote-desktop-10/id1295203466">Version 10</a> from the MacOS App Store.<br>
    </p>

    <div class="img-container">
      <a href="/assets/images/instructions/mac/rdp/1.png" target=_BLANK>
        <img src="/assets/images/instructions/mac/rdp/1.png">
      </a>
    </div>

    <div class="img-container">
      <a href="/assets/images/instructions/mac/rdp/2.png" target=_BLANK>
        <img src="/assets/images/instructions/mac/rdp/2.png">
      </a>
    </div>

    <p>  
      Provide the login credentials via the 'New' menu bar option, or download and import the provided .rdp config file from your VM creation confirmation email.
    </p>

    <div class="img-container">
      <a href="/assets/images/instructions/mac/rdp/3.png" target=_BLANK>
        <img src="/assets/images/instructions/mac/rdp/3.png">
      </a>
    </div>
    
    <p>
      Your connection should now be stored. To connect to the VM, simply double click on the menu item.
    </p>

    <div class="img-container">
      <a href="/assets/images/instructions/mac/rdp/4.png" target=_BLANK>
        <img src="/assets/images/instructions/mac/rdp/4.png">
      </a>
    </div>

    <h3>Linux (desktop environment) instructions</h3>
    <small>Example shown running Fedora Workstation 31 with the GNOME 3.34 Desktop Environment</small><br><br>
    <p>
      We recommend using Remmina for RDP on Linux as it's readily available for most major distributions. If you can't install it directly using your package manager, you can find Remmina via the distro-agnostic Flatpak and the Flathub repository. Please note that Remmina currently isn't available via EPEL.<br>
    </p>
    
    <p>
      You can check if your distribution's package manager offers Remmina <a target="_BLANK" href="https://remmina.org/how-to-install-remmina/">here</a>.<br>
    </p>

    <p>
      If Flatpak isn't installed by default on your distribution, you can install and enable it via your package manager.<br>
      You can find instructions for your specific Linux distribution <a target="_BLANK" href="https://flatpak.org/setup/"> here</a>.<br>
      <br>
      Once installed, you can <a target="_BLANK" href="https://flathub.org/apps/details/org.remmina.Remmina">download Remmina</a> or run <code>flatpak install flathub org.remmina.Remmina</code> in Terminal.<br>
      With Remmina installed, you should be able to import the provided .rdp config file from the main menu by selecting Tools > Import<br>
      Failing that, you can manually specify the host, account name and password, which will have been provided in the email following the VMs deployment.
    </p>

    <div class="img-container">
      <a href="/assets/images/instructions/linux/rdp/1.png" target=_BLANK>
        <img src="/assets/images/instructions/linux/rdp/1.png">
      </a>
    </div>

    <div class="img-container">
      <a href="/assets/images/instructions/linux/rdp/1.png" target=_BLANK>
        <img src="/assets/images/instructions/linux/rdp/2.png">
      </a>
    </div>

    <div class="img-container">
      <a href="/assets/images/instructions/linux/rdp/1.png" target=_BLANK>
        <img src="/assets/images/instructions/linux/rdp/3.png">
      </a>
    </div>

  </section>

  <section>
    <h2>We'd love to hear from you</h2>
    <p>If you have any feedback on how to improve this guide, please send an email to us at <a href="info:gridz.io?subject=Remote Access Guide Suggestions">info@gridz.io</a>.</p>
  </section>
</div>

<app-footer></app-footer>