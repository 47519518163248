import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { PermissionsService } from 'app/core/services/permissions.service';
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class OrgAdminAuthGuard implements CanActivate {

  constructor(
    private permissionsService:PermissionsService,
    private snackBar: MatSnackBar) { }

    async canActivate() {
      let allowActivate = false;

      await this.permissionsService.getUserAndRoles()
        .toPromise()
        .then(res => {
          if (res.data.user.organisation_user_role_id === environment.userRoles.OrganisationAdmin) {
            allowActivate = true;
          } else {
            this.snackBar.open('You don\'t have permission to view \'Users\' section.', 'Close', { duration: 3000 });
          }
        })
        .catch(err => {
          this.snackBar.open(`Error: ${err.message}`, 'Close', { duration: 10000 });
        });

      return allowActivate;
    }
}
