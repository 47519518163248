import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { HttpTokenInterceptor } from '../core/interceptors/http-token.interceptor';
import { PermissionsService } from '../core/services/permissions.service';
import { AdminAuthGuard } from 'app/shared/security/guards/admin-auth.guard';
import { UserAuthGuard } from 'app/shared/security/guards/user-auth.guard';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { HttpImpersonationTokenInterceptor } from './interceptors/http-impersonation-token.interceptor';
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MatSnackBarModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpImpersonationTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true, deps:[PermissionsService, MatSnackBarModule, Router] },
    AdminAuthGuard,
    UserAuthGuard,

  ],
  declarations: []
})
export class CoreModule { }
