import { AuthService } from './core/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { PermissionsService } from 'app/core/services/permissions.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(
    public permissionsService:PermissionsService,
    public AuthService: AuthService
  ) {
   }

  ngOnInit(){
    
  }

}
