import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PermissionsService } from '../services/permissions.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';


export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private permissionsService: PermissionsService,
        private snackBar: MatSnackBar,
        private router: Router
      ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
            if (error.status === 401 || error.status === 403) {
              this.permissionsService.loading = false; //so that it may get to the logout component if this is from a page revisit

              if (this.router.url.indexOf('/auth/') > -1) {
                //login specific 401/403 errors
              } else{
                this.permissionsService.clear();
                // this.snackBar.open('You have been successfully logged out!', 'Close', { duration: 3000 });
                this.router.navigate(['auth/login']);
              }
            }

          return throwError(error);
        })
      )
  }
}
