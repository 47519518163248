import { AuthService } from '../../../core/services/auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {PermissionsService} from 'app/core/services/permissions.service';
import { EnvironmentsService } from 'app/core/services/environments.service';

@Injectable({
  providedIn: 'root',
})
export class UserAuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private permissionsService:PermissionsService,
        private environmentsService:EnvironmentsService,
        private authService: AuthService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        //has permissions service been init
        if(!this.permissionsService.initialized){
            this.permissionsService.init();
        }
       let user = this.permissionsService.user;
       if (user) {
            if([1,2].includes(user.type)){
                // check if permissions need to be reloaded
                // if(route.component['name'] == 'EnvironmentViewComponent'){
                //     return this.environmentsService.getUserAndRoles()
                //     .subs(res => {
                //         if(res.result == 'success'){
                //             window.localStorage.setItem('roles', JSON.stringify(res.data.roles));
                //             this.permissionsService.usersRolesSubject.next(res.data.roles);
                //             console.log(this.permissionsService.usersRolesSubject.value);
                //             return true;
                //         }else{
                //             this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
                //             return false;
                //         }
                //     });
                return true;

            }else{
                this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url }});
                return false;
            }
            // return true;

            //to be most secure, it is best to either have admin and
            //user front ends in seperate projects
            //or for each route, when going through the guard, a req can
            //be make the the auth sever to see the user type so that
            //it cannot be spoofed
            //however this is still less secure than
            //having two separete projects because the user type can still be spoofed if
            //the "hacker" changes the type when it is comning from the server with a man in the middle attack

        }else{
            //could be logged in but refreshed so the user obj will be null
            //so look for the access token in local storage
            if(window.localStorage.getItem('access_token')){

                // this.permissionsService.init();
                return true;
            }
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url }});
            return false;
        }


        // not logged in so redirect to login page with the return url
        // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        // return false;

    }
}
