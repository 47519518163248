<nav class="navbar main-nav navbar-fixed-top">
    <div class="container-fluid">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand grids-logo" routerLink="/my-environments" *ngIf="organisation.demo === 1">
                <img src="/assets/images/logos/logo-demo.svg" class="logo">
            </a>

            <a class="navbar-brand grids-logo" routerLink="/my-environments" *ngIf="organisation.demo !== 1">
                <img src="/assets/images/logos/{{organisation.organisation_logo}}" class="logo custom" *ngIf="customLogo">
                <img src="/assets/images/logos/logo-white.svg" class="logo" *ngIf="!customLogo">
            </a>
            
            <!-- <a class="navbar-brand navbar-title-wrap" routerLink="/my-environments">
                <h1 class="navbar-title ave-title">AVE Controller</h1>
            </a> -->

            <ul class="nav navbar-nav">

            </ul>
        </div>
        <div id="navbar" class="collapse navbar-collapse">
            <ul class="nav navbar-nav rhs-nav">
                <li class="active"><a routerLink="/my-environments">Home</a></li>
                <!-- <li><a [routerLink]="['/organisation-settings', user?.organisation_id]" *ngIf="user.super_admin">Settings</a></li> -->
                <!-- <li><a routerLink="/auth/logout">Log out</a></li> -->
                <li (mouseover)="toggleDropDown(true)" (click)="toggleDropDown(!showDropDown)">
                    <a>
                        <div class="impersonation-notification" *ngIf="user.impersonated">IMPERSONATING USER</div> 
                        {{user?.name}} <img src="assets/images/icons/dropdown-w.svg" class="nav-dropdown-icon">
                    </a>
                </li>
            </ul>
        </div>

        <div class="user-icon-menu" [ngClass]="{'hidden':!showDropDown}">
            <ul class="user-icon-menu-list">
                <!-- <li class="active"><a routerLink="/my-environments">Notifications</a></li> -->
                <li><a routerLink="/account-settings">Account Settings</a></li>
                <!-- <div class="dropdown-divider"></div> -->
                <li><a [routerLink]="['/organisation-settings', user?.organisation_id]" *ngIf="user.super_admin">Organisation Settings</a></li>
                <!-- <div class="dropdown-divider"></div> -->
                <li><a routerLink="/auth/logout">Log out</a></li>
                <!-- <li *ngIf="!user?.impersonated"><a routerLink="/auth/logout">Log out</a></li>
                <li *ngIf="user?.impersonated"><a (click)="stopImpersonating()">Stop Impersonating</a></li> -->
            </ul>
        </div> 
    </div>
</nav>
<div class="nav-gap"></div>
