import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {PermissionsService} from 'app/core/services/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class AdminAuthGuard implements CanActivate {

    constructor(private router: Router, private permissionsService:PermissionsService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let user = this.permissionsService.user;
        if (user) {
            // logged in so return true
            if(user.type == 2){
                return true;
            }else{
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
                return false;
            }

            //to be most secure, it is best to either have admin and 
            //user front ends in seperate projects
            //or for each route, when going through the guard, a req can 
            //be make the the auth sever to see the user type so that 
            //it cannot be spoofed
            //however this is still less secure than
            //having two separete projects because the user type can still be spoofed if 
            //the "hacker" changes the type when it is comning from the server with a man in the middle attack 

        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}