import { Component, OnInit } from '@angular/core';
import {PermissionsService} from 'app/core/services/permissions.service';
import { AuthService } from 'app/core/services/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent implements OnInit {
  showDropDown = false;
  organisationId;
  user;
  customLogo = false;
  organisation;
  constructor(
    private permissionsService: PermissionsService, 
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.user = this.permissionsService.user;
    // TODO: Refactor this, because there are some shady things going on with permissionsService response
    if (this.permissionsService.user.organisation === undefined) {
      this.organisation = this.permissionsService.organisation
    } else {
      this.organisation = this.permissionsService.user.organisation;
    }
    if (this.organisation) {
      if (this.organisation.organisation_logo) {
        this.customLogo = this.organisation.organisation_logo;
      }
    } else {
      this.customLogo = false;
    }
  }

  toggleDropDown(toggle) {
    this.showDropDown = toggle;
  }

  stopImpersonating(){
    var token = parseInt(window.localStorage.getItem("impersonatedUserToken"));
    this.authService.stopImpersonation(token)
    .subscribe(res => {
      // if (res.result == 'success'){
      //   window.localStorage.clear();
      //   this.permissionsService.user = null;
      //   this.permissionsService.userRoles = null;
      //   this.permissionsService.loading = false;
      //   this.router.navigate(['/login']);
      //   this.snackBar.open('Impersonation session has ended.', 'Close', { duration: 3000 })
      // }
    });
  }
}
