
import {throwError as observableThrowError, BehaviorSubject, Observable} from 'rxjs';
import {map,  catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Router} from '@angular/router';

import { Environment } from 'app/shared/models/environment.model';

import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})

export class EnvironmentsService {
    private apiUrl = environment.apiUrl;
    public accessToken;
    private headers;
    options;
    private tempTest; // variable to test instance
    private environmentPolicyUpdatedSource = new BehaviorSubject(0);
    environmentPolicyUpdated = this.environmentPolicyUpdatedSource.asObservable();

    constructor(private http: HttpClient, private router: Router, private apiService: ApiService) {
        // used to test how many times it is injected
        this.tempTest = Math.floor(Math.random() * Math.floor(50));
        console.log('env service constructor ' + this.tempTest);


    }



    uploadEnvironmentCover(data) {
        // overide the headres
        // let customHeaders = new Headers({
        //     "Authorization": "Bearer " + this.accessToken,
        //     "Impersonated-User-Token" : parseInt(window.localStorage.getItem("impersonatedUserToken"))
        // });
        // let customOptions = new RequestOptions({ headers: customHeaders});
        // return this.apiService.post('/environment/upload-environment-cover', data)


    }

    editOrganisationAddress(data) {
        return this.apiService.post('/organisation/edit-organisation-address', data)


    }
    addPaymentCard(data) {
        return this.apiService.post('/payments/add-payment-card', data)


    }

    deletePaymentCard(data) {
        return this.apiService.post('/payments/delete-payment-card', data)


    }

    setCardAsDefault(data) {
        return this.apiService.post('/payments/set-card-as-default', data)


    }

    getOrganisationCards(organisationId) {
        return this.apiService.get(`/payments/organisation/${organisationId}/get-all-cards`)


    }

    removeEnvironmentCover(data) {
        return this.apiService.post('/environment/remove-environment-cover', data)


    }

    getOrganisation() {
        return this.apiService.get(`/organisation/get-organisation`)


    }

    toggleEnvironmentDefaultVirtualMachineManagement(environmentId) {
        return this.apiService.post(`/environment/${environmentId}/toggle-environment-default-virtual-machine-management`, null)


    }

    // uploadContract(data){
    //     //overide the headres
    //     let customHeaders = new Headers({
    //         "Authorization": "Bearer " + this.accessToken,
    //         "Impersonated-User-Token" : parseInt(window.localStorage.getItem("impersonatedUserToken"))
    //     });
    //     let customOptions = new RequestOptions({ headers: customHeaders});
    //     return this.apiService.post('/admin/contract-upload', data, customOptions)

    //
    // }

    getOrganisationEnvironmentContracts() {
        return this.apiService.get('/organisation/get-all-contracts')


    }
    downloadContract$(environmentId: number): Observable<any> {
        return this.apiService.getBlob$(`/environment/${environmentId}/download-contract`);
    }

    downloadChangeRequest$(changeRequestId: number): Observable<any> {
        return this.apiService.getBlob$(`/change-request/${changeRequestId}/download-change-request`);
    }


    getContract(data) {
        return this.apiService.post('/admin/get-contract', data)


    }
    changeContractLength(data) {
        return this.apiService.post('/contract/change-contract-length', data)


    }
    approveContractRequest(data) {
        return this.apiService.post('/environment/approve-contract-request', data)


    }

    deployEnvironment(data) {
        return this.apiService.post('/environment/deploy-contract-request', data)


    }

    newEnvironment(data) {
        return this.apiService.post('/new-environment', data)


    }

    newEnvironmentGroup(data) {
        return this.apiService.post('/new-environment-group', data)


    }

    deleteEnvironmentGroup(data) {
        return this.apiService.post('/delete-environment-group', data)


    }

    deleteEnvironment(data) {
        return this.apiService.post('/environment/delete-environment', data)


    }

    getEnvironmentLocations() {
        return this.apiService.get('/environment-locations')


    }

    renameEnvironmentGroup(data) {
        return this.apiService.post('/environment/rename-environment-group', data)


    }

    renameEnvironment(data) {
        return this.apiService.post('/environment/rename-environment', data)


    }

    renameTier(data) {
        return this.apiService.post('/environment/tier/rename-tier', data)


    }

    updateEnvironmentGroup(data) {
        return this.apiService.post('/update-environment-group', data)


    }

    pinEnvironment(data) {
        return this.apiService.post('/pin-environment', data)


    }

    unPinEnvironment(data) {
        return this.apiService.post('/unpin-environment', data)


    }

    getEnvironments() {
        return this.apiService.get('/environments')


    }

    getCurrentEnvironmentSnapshot(environmentId) {
        return this.apiService.get(`/environment/${environmentId}/get-current-snapshot`)


    }

    getAllEnvironmentSnapshots(environmentId) {
        return this.apiService.get(`/environment/${environmentId}/get-all-snapshots`)


    }

    createEnvironmentSnapshot(environmentId) {
        return this.apiService.get(`/environment/create-snapshot/${environmentId}`)


    }
    deleteEnvironmentSnapshot(data) {
        return this.apiService.post(`/environment/delete-snapshot`, data)


    }

    getEnvironmentSnapshot(snapshotId) {
        return this.apiService.get(`/environment/snapshot/${snapshotId}`)


    }

    revertEnvironmentToSnapshot(snapshotId) {
        return this.apiService.get(`/environment/revert-to-snapshot/${snapshotId}`)


    }

    getEnvironmentTemplates() {
        return this.apiService.get('/environment-templates')


    }

    queryCloneableEnvironmentsAndTemplates(data) {
        return this.apiService.post('/environments/query-cloneable-environments-and-templates', data)


    }

    createEnvironmentFromEnvironmentOrTemplate(data) {
        return this.apiService.post('/environment/create-environment-from-environment-or-template', data)


    }

    getEnvironment(environmentId) {
        return this.apiService.get(`/environment/${environmentId}`);
        // .pipe(catchError((error:any) => observableThrowError(error || 'Server error')));

    }

    getEnvironmentMonitoring(data) {
        return this.apiService.post(`/environment/get-monitoring-data`, data)


    }

    getEnvironmentMonitoringAlerts(data) {
        return this.apiService.post(`/environment/monitoring/get-alerts`, data)


    }

    createMonitoringAlert(data) {
        return this.apiService.post(`/environment/monitoring/create-alert`, data)


    }

    deleteMonitoringAlert(data) {
        return this.apiService.post(`/environment/monitoring/delete-alert`, data)


    }

    pauseMonitoringAlert(data) {
        return this.apiService.post(`/environment/monitoring/pause-alert`, data)


    }

    compareEnvironmentAws(environmentId) {
        return this.apiService.get(`/environment/${environmentId}/compare/aws`)


    }

    importAws(data) {
        return this.apiService.post(`/environment/aws-import`, data)


    }

    importAwsVirtualMachines(data) {
        return this.apiService.post(`/environment/aws-import/virtual-machines`, data)


    }

    requoteAwsImport(data) {
        return this.apiService.post(`/environment/aws-import/requote`, data)


    }

    createEnvironmentFromAwsImport(data) {
        return this.apiService.post(`/environment/aws-import/new`, data)


    }

    getEnvironmentFirewallPolicyGroups(environmentId) {
        return this.apiService.get(`/environment/${environmentId}/firewall-policy-groups`)


    }

    getAllFirewallPolicies(environmentId) {
        return this.apiService.get(`/environment/${environmentId}/firewall-policies`)


    }

    createFirewallPolicyGroup(data) {
        return this.apiService.post(`/environment/create-firewall-policy-group`, data)


    }


    getCart(data): Observable<any> {
        return this.apiService.post(`/environment/cart`, data);
    }

    getEnvironmentForecast(data) {
        return this.apiService.post(`/environment/forecast`, data)


    }
    getOrganisationForecast(data) {
        return this.apiService.post(`/organisation/forecast`, data)


    }

    createAddon(data) {
        // convert to JSON string
        return this.apiService.post('/new-addon', data)


    }

    // Should really be in a different service
    createTempVirtualMachine(virtualMachines) {
        // convert to JSON string
        return this.apiService.post('/new-virtual-machines', virtualMachines)


    }

    createAwsVirtualMachine(virtualMachine) {
        // convert to JSON string
        return this.apiService.post('/new-aws-virtual-machine', virtualMachine)


    }

    removeAWSVirtualMachine(data) {
        // convert to JSON string
        return this.apiService.post('/environment/remove-aws-virtual-machine', data)


    }

    terminateAWSVirtualMachine(data) {
        // convert to JSON string
        return this.apiService.post('/environment/terminate-aws-virtual-machine', data)


    }

    getAwsAmis(data) {
        return this.apiService.post('/environment/aws/get-aws-amis', data)


    }

    getAwsSecurityGroups() {
        return this.apiService.get('/environment/aws/get-security-groups')


    }

    getAwsRegions() {
        return this.apiService.get('/environment/aws/regions')


    }

    createAzureVirtualMachine(virtualMachine) {
        return this.apiService.post('/new-azure-virtual-machine', virtualMachine)


    }

    editVirtualMachine(data) {
        return this.apiService.post('/edit-virtual-machine', data)


    }

    getOperatingSystems() {
        return this.apiService.get('/get-operating-systems')


    }

    getOrganisationTemplates$(organisationId: number): Observable<any> {
      return this.apiService.get(`/organisations/${organisationId}/os-templates`);
    }

    getVirtualMachine() {
        return this.apiService.get('/environment/get-virtual-machine')


    }

    getAwsVirtualMachine(id) {
        return this.apiService.get('/environment/get-aws-virtual-machine/' + id)


    }


    getTagSuggestions() {
        return this.apiService.get('/tags/get-tag-suggestions')


    }

    getTagSuggestionsQuery(data) {
        return this.apiService.post('/tags/get-tag-suggestions-query', data)


    }

    getResourceTags(data) {
        return this.apiService.post('/tags/get-resource-tags', data)


    }

    addResourceTag(data) {
        return this.apiService.post('/tags/add-resource-tag', data)


    }
    deleteResourceTag(data) {
        return this.apiService.post('/tags/delete-resource-tag', data)


    }

    getRemoteAccessDetails(virtualMachineId) {
        return this.apiService.get('/virtual-machine/' + virtualMachineId + '/get-remote-access-details')


    }

    getVirtualMachineStorageTypes() {
        return this.apiService.get('/virtual-machine/get-storage-types')


    }
    getEnvironmentVirtualMachines(environmentId) {
        return this.apiService.get(`/environment/${environmentId}/get-virtual-machines`)


    }

    getVirtualMachineQuote(data) {
        return this.apiService.post('/get-virtual-machine-quote', data)


    }

    getBackupStorageQuote(data) {
        return this.apiService.post('/environment/get-backup-storage-quote', data)


    }

    deleteVirtualMachine(data) {
        return this.apiService.post('/environment/delete-virtual-machine', data)


    }

    cloneVirtualMachine(data) {
        return this.apiService.post('/virtual-machine/clone-virtual-machine', data)


    }

    powerStateChange(data) {
        return this.apiService.post('/virtual-machine/power-state-change', data)


    }

    cancelPowerStateChange(data) {
        return this.apiService.post('/virtual-machine/cancel-power-state-change', data)


    }

    awsPowerStateChange(data) {
        return this.apiService.post('/aws-virtual-machine/power-state-change', data)


    }

    awsCancelPowerStateChange(data) {
        return this.apiService.post('/aws-virtual-machine/cancel-power-state-change', data)


    }

    getAwsVirtualMachines(data) {
        return this.apiService.post('/virtual-machines/aws/get-virtual-machines', data)


    }

    virtualMachineAssignIpAddress(data) {
        return this.apiService.post('/environment/virtual-machine-assign-ip', data)


    }

    virtualMachineUnAssignIpAddress(data) {
        return this.apiService.post('/environment/virtual-machine-unassign-ip', data)


    }

    addIpAddress(data) {
        return this.apiService.post(`/environment/add-ip-address`, data)


    }

    deleteIpAddress(data) {
        return this.apiService.post(`/environment/delete-ip-address`, data)


    }


    getEnvironmentIpAddresses(environmentId) {
        return this.apiService.get(`/environment/${environmentId}/get-ip-addresses`)


    }

    contractRequest(data) {
        return this.apiService.post('/environment/contract-request', data)


    }
    contractRequestEscalation(data) {
        return this.apiService.post('/environment/contract-request-escalation', data)


    }

    revertChanges(data) {
        return this.apiService.post('/environment/revert-changes', data)


    }

    deploy(data) {
        return this.apiService.post('/environment/deploy-contract-request', data)


    }

    getChangeRequestActions(environmentId) {
        return this.apiService.get(`/environment/get-change-request-actions/${environmentId}`)


    }

    getChangeRequests(environmentId) {
        return this.apiService.get(`/environment/${environmentId}/get-change-requests`)


    }

    getAllChangeRequests() {
        return this.apiService.get(`/admin/get-all-change-requests`)


    }

    requestChanges(data) {
        return this.apiService.post('/environment/request-changes', data)


    }

    approveChangeRequest(data) {
        return this.apiService.post('/admin/approve-change-request', data)


    }

    deployChangeRequest(data) {
        return this.apiService.post('/admin/deploy-change-request', data)


    }

    moveVirtualMachinesToNewTier(data) {
        return this.apiService.post('/tier/move-virtual-machines', data)


    }

    addTier(tier) {
        return this.apiService.post('/new-tier', tier);
    }

    deleteTier(tierId) {
        return this.apiService.delete(`/delete-tier/${tierId}`);
    }

    // temp for testing
    getOrganisationLogs() {
        return this.apiService.get('/organisation/logs');
    }

    getEnvironmentLog(environmentId) {
        return this.apiService.get(`/environment/${environmentId}/logs`);
    }

    queryEnvironmentLogs(data) {
        return this.apiService.post(`/environment/query-logs`, data);
    }

    queryOrganisationLogs(data) {
        return this.apiService.post(`/organisation/query-logs`, data);
    }

    createPassword(data) {
        return this.apiService.post('/organisation/user/create-password', data);
    }

    resetPassword(data) {
        return this.apiService.post('/organisation/user/reset-password', data);
    }

    requestPasswordReset(data) {
        return this.apiService.post('/organisation/user/request-password-reset', data);
    }

    getUserPermissions() {
        return this.apiService.get('/get-user-permissions');
    }

    getUserAndRoles() {
        return this.apiService.get('/auth/get-user-and-roles');
    }

    getEnvironmentUsers(environmentId) {
        return this.apiService.get(`/environment/${environmentId}/get-all-users`);
    }

    changeEnvironmentUserRole(data) {
        return this.apiService.post(`/environment/change-user-role`, data);
    }

    deleteUser(data) {
        return this.apiService.post(`/users/delete-user`, data);
    }

    changeEnvironmentUserAccess(data) {
        return this.apiService.post(`/environment/change-user-access`, data);
    }

    changeOrganisationUserRole(data) {
        return this.apiService.post(`/organisation/change-user-role`, data);
    }

    getOrganisationUsers() {
        return this.apiService.get(`/organisation/get-all-users`);
    }

    createOrganisationUser(data): Observable<any> {
        return this.apiService.post(`/organisation/create-user`, { data });
    }

    editLoadBalancer(data) {
        return this.apiService.post('/edit-load-balancer', data);
    }

    createLoadBalancer(data) {
        return this.apiService.post('/create-load-balancer', data);
    }

    deleteLoadBalancer(data) {
        return this.apiService.post('/delete-load-balancer', data);
    }

    createNetwork(data) {
        return this.apiService.post('/create-network', data);
    }

    createAwsNetwork(data) {
        return this.apiService.post('/aws-create-network', data);
    }

    renameNetwork(data) {
        return this.apiService.post('/environment/network/rename-network', data);
    }

    connectToNetwork(data) {
        return this.apiService.post('/connect-to-network', data);
    }

    disconnectFromNetwork(data) {
        return this.apiService.post('/disconnect-from-network', data);
    }

    queryEnvironment(data) {
        return this.apiService.post('/query-environment', data);
    }

    createClearSwiftSeg(data) {
        return this.apiService.post('/create-seg', data);
    }

    updateVirtualMachineNotes(data) {
       return this.apiService.post('/update-virtual-machine-notes', data);
    }

    createFirewallPolicy(data) {
        return this.apiService.post('/environment/create-firewall-policy', data);
    }

    createInboundNatPolicy(data) {
        return this.apiService.post('/environment/create-inbound-nat-policy', data);
    }

    createOutboundNatPolicy(data) {
        return this.apiService.post('/environment/create-outbound-nat-policy', data);
    }

    deleteFirewallPolicy(data) {
        return this.apiService.post('/environment/firewall/delete-firewall-policy', data);
    }

    toggleEnableDisableFirewallPolicy(data) {
        return this.apiService.post('/environment/firewall/toggle-enable-disable-firewall-policy', data);
    }

    reorderPolicy(data) {
        return this.apiService.post('/environment/firewall/reorder-policy', data);
    }

    reorderTier(data) {
        return this.apiService.post('/environment/tier/reorder-tier', data);
    }

    getOrganisationSafetynetPolicies() {
        return this.apiService.get('/organisation/get-safteynet-policies');
    }

    switchManagementPackage(data) {
        return this.apiService.post('/environment/switch-management-package', data);
    }

    switchBandwidthPackage(data) {
        return this.apiService.post('/environment/switch-bandwidth-package', data);
    }

    createSafetynetPolicy$(data) {
        return this.apiService.post('/organisation/create-safetynet-policy', data);
    }

    toggleVirtualMachineSafetynetEnabled(data) {
        return this.apiService.post('/virtual-machine/toggle-safetynet-policy-enabled', data);
    }

    editBackupStorage(data) {
        return this.apiService.post('/environment/edit-backup-storage', data);
    }

    removeEnvironmentSafetynetPolicy(environmentId) {
        return this.apiService.get('/environment/' + environmentId + '/remove-safetynet-policy');
    }

    updateEnvironmentSafetynetPolicy(data) {
        return this.apiService.post('/environment/update-safetynet-policy', data)

        .pipe(
            map((res) => {
                this.environmentPolicyUpdatedSource.next(data.environment_id);
                // return res.json();

                // return res;
            })
        );
    }

    updateLocation$(environmentId, locationId) {
        const data = {
            environment_location_id: locationId
        }

        return this.apiService.patch(`/environments/${environmentId}/location`, data);
    }
}
