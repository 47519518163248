import { Component, OnInit } from '@angular/core';
import { PermissionsService } from 'app/core/services/permissions.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  user;
  customLogo = false;
  organisation;
  constructor(private permissionsService:PermissionsService) { }

  ngOnInit() {
    this.user = this.permissionsService.user;
    this.organisation = this.permissionsService.organisation;
    if(this.organisation){
      if(this.organisation.organisation_logo){
        this.customLogo = this.organisation.organisation_logo;
      }
    }else{
      this.customLogo = false;
    }
  }

}
