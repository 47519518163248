import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root',
})
export class HttpImpersonationTokenInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };

    const token = window.localStorage.getItem('impersonatedUserToken');

    if (token) {
      headersConfig['Impersonated-User-Token'] = token;
    }


    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(request);
  }

  routeIncludes() :boolean{
    var includes = false;



    return includes;
  }
}
