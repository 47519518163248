

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

//Custom modules
import { CoreModule } from 'app/core/core.module';


import { AppComponent } from './app.component';

import { OrgAdminAuthGuard } from './shared/security/guards/org-admin-auth.guard';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    // AngularDraggableModule,

    //custom modules
    CoreModule,
    AppRoutingModule
  ],
  providers: [
    OrgAdminAuthGuard
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
