import {Injectable} from '@angular/core';

import {EnvironmentsService} from './environments.service';
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})


// responsible for holding the current logged in user as well as the users roles for all environments in the users organisation
// the data gets reset when accessing certain pages for various reasons

export class PermissionsService {
    user = null;
    organisation = null;
    userRoles = null;
    private apiUrl = environment.apiUrl;
    public accessToken;
    private headers;
    options;
    loading = false;
    public tempTest; // variable to test instance
    initialized = false;
    constructor(
      private apiService: ApiService,
      private environmentsService: EnvironmentsService,
      private snackBar: MatSnackBar,
      private authService: AuthService,
      private router: Router
    ) {
        // let refreshToken = window.localStorage.getItem('refresh_token');
        // if(refreshToken){
        //     this.authService.refreshToken();
        //     //TODO: call init() from this by either subbing or another method to try to refresh the token before starting a new session after refreshing
        //     // incase the token is not valid but the refresh token is still valid
        // }
        this.tempTest = Math.floor(Math.random() * Math.floor(50));
        console.log('perm service constructor ' + this.tempTest);

        // var currentURL = location.href;
        // console.log(currentURL);
        // if(currentURL.indexOf('impersonate') > -1){
        //     console.log('by pass');
        //     this.clear();
        // }else{
        // }
    }

    init(redirect = false) {
      this.loading = true;
      this.environmentsService.getUserAndRoles()
      .subscribe(res => {
          if (res.result === 'success') {
                console.log('starting perm init');
                this.user = res.data.user;
                this.userRoles = res.data.roles;
                this.organisation = res.data.organisation;


                // TODO: THIS SHOULD COME FROM THE API
                // this.user.impersonated = false;
                // let impersonatedUserToken = window.localStorage.getItem('impersonatedUserToken');
                // if (typeof impersonatedUserToken !== 'undefined' && impersonatedUserToken !== null){
                //     this.user.impersonated = true;
                // }
                this.loading = false;
                if (redirect) {
                    this.router.navigate(['/my-environments']);
                }
                this.initialized = true;
          }
      });
    }



    reset() {
        this.userRoles = [];
        this.init();
    }

    clear() {
        window.localStorage.clear();

        this.user = null;
        this.userRoles = null;
        this.loading = false;
    }

    isSuperAdmin() {
      return (this.user.super_admin) ? true : false;
    }

    doesUserHaveOrganisationRole(roleIdArray) {
        let hasRole = false;
        for (const roleId of roleIdArray) {
            if (roleId === this.user?.organisation_user_role_id) {
                hasRole = true;
                break;
            }
        }
        return hasRole;
    }

    doesUserHaveRole(environmentId, roleIdArray) {
        let hasRole = false;
        const response = this.userRoles;
        for (const roleId of roleIdArray) {
            var responseFind = response.find(res => res['environment_id'] == environmentId && res['id'] == roleId );
            if (responseFind) {
                break;
            }
        }
        if (!responseFind) {
            hasRole = false;
        } else {
            hasRole = true;
        }
        return hasRole;
    }

    getUserRoleId(environmentId) {
        const response = this.userRoles;
        const environmentRole = this.userRoles.find(res => res['environment_id'] == environmentId);
        return environmentRole['id'];
    }

    isEnvironmentUnlocked(environmentStatus) {
        // uses the status of the environment to determine this
        let unlocked = false;
        if (environmentStatus === 1) {
            unlocked = true;
        } else if (environmentStatus === 2) {
            unlocked = true;
        } else if (environmentStatus === 3) {
            unlocked = false;
        } else if (environmentStatus === 4) {
            unlocked = false;
        } else if (environmentStatus === 5) {
            unlocked = true;
        } else if (environmentStatus === 6) {
            unlocked = false;
        } else if (environmentStatus === 7) {
            unlocked = false;
        } else if (environmentStatus === 8) {
            unlocked = true;
        } else if (environmentStatus === 9) {
            unlocked = false;
        } else if (environmentStatus === 10) {
            unlocked = false;
        } else if (environmentStatus === 11) {
            unlocked = true;
        } else if (environmentStatus === 12) {
            unlocked = true;
        }
        return unlocked;
    }

    // HTTP

    // TODO: refactor environment service and remove this method from there
    getUserAndRoles() {
        return this.apiService.get('/auth/get-user-and-roles')

    }
}
