import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { RemoteAccessComponent } from "./help/remote-access/remote-access.component";

const routes: Routes = [
  {path:'', redirectTo: 'my-environments', pathMatch:'full'},
  
  {path:'help/remote-access', component: RemoteAccessComponent},

  //lazy loaded modules
  {
    path:'my-environments', 
    loadChildren:() => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path:'view-environment', 
    loadChildren:() => import('./view-environment/view-environment.module').then(m => m.ViewEnvironmentModule)
  },
  {
    path:'auth',
    loadChildren:() => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path:'environment-settings',
    loadChildren:() => import('./environment-settings/environment-settings.module').then(m => m.EnvironmentSettingsModule)
  },
  {
    path:'organisation-settings',
    loadChildren:() => import('./organisation-settings/organisation-settings.module').then(m => m.OrganisationSettingsModule)
  },
  {
    path:'environment',
    loadChildren:() => import('./view-environment/view-environment.module').then(m => m.ViewEnvironmentModule)
  },

  {
    path:'account-settings',
    loadChildren:() => import('./account-settings/account-settings.module').then(m => m.AccountSettingsModule)
  },
  //end lazy loaded modules



  
  
  //shared
  // {path:'no-permission', component: NoPermissionPageComponent},
  // {path:'forbidden', component: ForbiddenComponent},
  // {path:'not-found', component: NotFoundComponent},
  // {path:'bad-request', component: BadRequestComponent},
  // {path:'server-error', component: ServerErrorComponent},
  // {path: 'sfauth', component: SalesforceAuthConfirmedComponent},

  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
