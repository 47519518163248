<div *ngIf="true" id="footer">

  <div class="footer-links">
    <div class="container">
      <div class="row bot-mar-md">

        <div class="col-md-4 col-sm-4">
          <img class="brand" src="/assets/images/logos/logo-white.svg" *ngIf="!customLogo">
          <img class="brand"  src="/assets/images/logos/{{organisation.organisation_logo}}" *ngIf="customLogo">
          <br><br>
          <p class="strapline" *ngIf="!customLogo">Intuitive cloud management</p>
        </div>

        <div class="col-md-4 col-sm-4">
          <ul class="links">
            <li><a href="mailto:help@gridz.io?subject=Customer Feedback">Submit Feedback</a></li>
            <li><a href="mailto:help@gridz.io?subject=Support Request">Contact Support</a></li>
            <li><a [routerLink]="['/help/remote-access']">Remote Access Guide</a></li>
          </ul>
        </div>

        <div class="col-md-4 col-sm-4">
          <ul class="links">
            <li>
              <a href="https://www.iubenda.com/privacy-policy/64353729" target="_BLANK" class="iubenda-white no-brand iubenda-embed" title="Privacy Policy ">Privacy Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
            </li>
            <li>
              <a href="https://www.iubenda.com/privacy-policy/64353729/cookie-policy" target="_BLANK" class="iubenda-white no-brand iubenda-embed" title="Cookie Policy ">Cookie Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
            </li>
            <li><a href="https://www.zsah.net/terms-of-use" target="_BLANK">Terms and Conditions</a></li>
          </ul>
          <div class="social">
            <a href="https://www.linkedin.com/company/zsah/" target="_BLANK" class="social-link"><img class="social-icon linkedin"><img src="assets/images/icons/linkedin.svg" class="social-icon linkedin"></a>
            <a href="https://twitter.com/zsahltd" target="_BLANK" class="social-link"><img src="/assets/images/icons/twitter.svg" class="social-icon twitter"></a>
            <a href="https://www.instagram.com/zsahltd/" target="_BLANK" class="social-link"><img class="social-icon instagram"><img src="assets/images/icons/instagram.svg" class="social-icon instagram"></a>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="footer-copy">
    <div class="container">
      <p>© 2021 · Powered by  <a href="https://www.zsah.net" target="_BLANK">zsah ltd</a> · Leaders in Managed Technology Services.</p>
    </div>
  </div>

</div>